.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: darkslategrey;
  color: white;
  position: absolute;
  font-size: calc(.7 * (1.3vh + 0.7vw));
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  
}

.footer a {
  padding-right: 15px;
  font-size: calc(.7 * (1.3vh + 0.7vw));
  color: white;
}

.footer a:hover {
  color:lightgrey
}

.footer .MuiIconButton-colorWhite {
  color: white;
}