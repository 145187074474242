
main {
  min-height: calc(95vh - 60px);
  background-color: rgb(224, 235, 235);
  font-family: "Inter", sans-serif;
font-weight: 400;
font-size: 1rem;
line-height: 1.5;
padding-left: 20px;
padding-right: 20px;

}

.App {
  
  text-align: center;
 
}

.CardGrid
{
  padding: 10px 0;

}

.Card
{
  padding-top: 20px 0; 
  height: 100%;
  display: flex;
  flex-direction: column;

  
  
}

.CardMedia
{
  padding-top: 56.25%; 
  
  
}

.CardContent
{
  flex-grow: 1;
  
}
.Footer
{
  padding-top: 150px 0; 
  
}

.TopPad
{
  padding-top: 10px 0; 
  padding: 10px;
  
} 

.App-logo {
  margin-top: 100px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Button-Load {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
  padding: 10px;

}


